import React from "react"
import PropTypes from "prop-types"

import MaterialAlert from '@material-ui/lab/Alert';

class Alerts extends React.Component {
  render () {
    return (
      <MaterialAlert variant={this.props.variant} severity={this.props.severity}>{this.props.text}</MaterialAlert>
    );
  }
}

Alerts.propTypes = {
  severity:  PropTypes.string,
  variant:   PropTypes.string,
  text:      PropTypes.string,
};

export default Alerts;
